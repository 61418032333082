/* eslint-disable no-unused-vars */
import alertsApi from '@/api/alerts.api';
import thingApi from '@/api/thing.api';
import { serviceTypeIcon } from '@/constants/alert.constant';
import i18n from '@/i18n';
import { calcPerformance, getNowLongDateUTC, getYieldUnit, parseTimestamp } from '@/tools/functions';
import { ReportConversionUtil, Util } from '@colven/common-domain-lib/lib';
import moment from 'moment';
import { ALERT_TYPES } from '../constants/alert.constant';
import store from "../store/store";
import { unitParser } from '@colven/common-domain-lib/lib';

/**
 * Obtener los datos para el reporte de alertas
 * @param {*} filters
 * @returns
 */
const getAlertsData = async (filters) => {
    try {
        const response = await alertsApi.getAlertsData(filters)
        return processData(response.data, filters.configurationsData.selectedYieldUnit)
    } catch (exception) {
        console.error(exception)
        return []
    }
}

/**
 * Procesar los datos para el reporte de alertas
 * @param {*} data
 * @returns
 */
const processData = async (data, yieldUnit) => {
    // mapa id - datos de tipos de servicios
    const serviceTypes = (await thingApi.getServiceTypes()).data
    const serviceTypeMap = {}
    serviceTypes.forEach(serviceType => serviceTypeMap[serviceType.key] = serviceType)

    // procesamiento de datos
    data.forEach(element => {
        // cosumo actual
        if (element.varilla.consumed && element.varilla.distance) {
            element.currentPerformance = `${calcPerformance(element.varilla.consumed, element.varilla.distance, yieldUnit)} ${getYieldUnit(yieldUnit)}`
        }

        // nivel actual
        const fuelLevel1 = element.varilla.fuelLevel1 ? Number(element.varilla.fuelLevel1) : 0
        const fuelLevel2 = element.varilla.fuelLevel2 ? Number(element.varilla.fuelLevel2) : 0
        const fuelLevel3 = element.varilla.fuelLevel3 ? Number(element.varilla.fuelLevel3) : 0
        const fuelLevel4 = element.varilla.fuelLevel4 ? Number(element.varilla.fuelLevel4) : 0
        element.currentFuel = unitParser.parseVolumen(Util.roundDecimals((fuelLevel1 + fuelLevel2 + fuelLevel3 + fuelLevel4)),store.getters['user/getInternationalization'].unit, true);

        // tipo de servicio
        const serviceType = element.serviceType ? serviceTypeMap[element.serviceType] : null
        if (serviceType) {
            const locale = i18n.locale ? i18n.locale : navigator.language
            element.serviceTypeName = serviceType.name[locale]
            element.serviceTypeIcon = serviceTypeIcon[serviceType.key] ? serviceTypeIcon[serviceType.key] : 'fa-truck'
        }
        setAlert(element);
    })

    return data
}

const setAlert = (element) => {
    const currentUTCDate = getNowLongDateUTC();
    let alertType = null;
    if (element.varilla && element.varilla.lastAlertTypeKey && (element.varilla.lastAlertTimestamp && (currentUTCDate - element.varilla.lastAlertTimestamp) <= 3600)) {
        alertType = element.varilla.lastAlertTypeKey;
        element.alertTimeFormmatted = " - " + getTimeFromDate(element.varilla.lastAlertTimestamp);
    } else {
        alertType = 'NO_ALERT';
    }
    element.alertIcon = ALERT_TYPES[alertType].icon;
    element.alertColor = ALERT_TYPES[alertType].color;
    element.alert = i18n.t(ALERT_TYPES[alertType].text);
    element.type = ALERT_TYPES[alertType].type;
    const { timestampFormatted, timestampColor } = getTimestampFormatted(element.varilla.lastComunicationTimestamp)
    element.timestampFormatted = timestampFormatted;
    element.timestampColor = timestampColor;
}

const getTimeFromDate = timestamp => {
    const offset = Number(store.getters["user/getEnterpriseTimezone"]);
    let response = unitParser.parseTime(timestamp, store.getters['user/getInternationalization'].date, offset);
    return  response
    // const date = new Date(timestamp * 1000);
    // const pad = num => ("0" + num).slice(-2);
    // let hours = date.getHours(),
    //     minutes = date.getMinutes(),
    //     seconds = date.getSeconds();
    // return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds)
}

/**
 * Formatear el timestamp (texto y color)
 * @returns
 */
const getTimestampFormatted = (timestamp) => {
    const result = { timestampFormatted: null, timestampColor: null }
    if (timestamp != null) {
        result.timestampFormatted = internationalizationDate(timestamp)
        result.timestampColor = ReportConversionUtil.getTimestampColor(timestamp)
    }
    return result
}

const internationalizationDate = (timestamp, timezone = localStorage.getItem('enterpriseTimezone'))=> {
    return unitParser.parseDateAndTime(timestamp, store.getters['user/getInternationalization'].dateMask, store.getters['user/getInternationalization'].date, Number(timezone));
}

export default {
    getAlertsData
}