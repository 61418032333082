import reportStorageApi from '@/api/report-storage.api'

/**
 * Obtener los datos de un reporte guardado en la base de datos
 * @param {*} reportId
 * @returns
 */
 const getReport = async (reportId) => {
    try {
        const response = await reportStorageApi.getReport(reportId)
        return response.data
    } catch (exception) {
        console.error(exception)
        return null
    }
}

export default {
    getReport
}