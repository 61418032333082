import { YieldUnits } from '@colven/common-domain-lib/lib';
import { ALERT_TYPE_ROUTE } from "../../../constants/alert.constant";

export default {
    name: 'AlertCardComponent',
    components: {},
    props: {
        thingId: {
            type: String,
            required: true
        },
        thingName: {
            type: String,
            required: true
        },
        timestampFormatted: {
            type: String,
            required: false,
            default: null
        },
        timestampColor: {
            type: String,
            required: false,
            default: null
        },
        serviceTypeName: {
            type: String,
            required: false,
            default: null
        },
        serviceTypeIcon: {
            type: String,
            required: false,
            default: null
        },
        currentPerformance: {
            type: String,
            required: false,
            default: null
        },
        currentFuel: {
            type: String,
            required: false,
            default: null
        },
        alertIcon: {
            type: String,
            required: false,
            default: 'fa-exclamation-circle'
        },
        alertColor: {
            type: String,
            required: false,
            default: '#2196F3'
        },
        alert: {
            type: String,
            required: false,
            default: null
        },
        type: {
            type: String,
            required: false,
            default: null
        },
        alertTimeFormmatted: {
            type: String,
            required: false,
            default: () => { return '' }
        }
    },
    data: () => ({
    }),
    methods: {
        goToReport(thingId, type, thingName) {
            const filters = {
                generalData: {
                    maxQuantity: 1,
                    selectedThings: [{ "id": thingId, "name": thingName, "enterpriseId": "1" }]
                },
                dateData: {
                    selectedDateAndTimeRange: "currentHour"
                },
                orderData: {
                    selectedSortOption: "newestData"
                },
                configurationsData: {
                    selectedYieldUnit: YieldUnits.KM_PER_LITER
                },
                rangeData: {
                    greaterThan: null,
                    lessThan: null
                }
            };
            this.$router.push({ name: ALERT_TYPE_ROUTE[type], params: filters });
        }
    }
}