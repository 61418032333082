/**
 * íconos se tipos de servicios
 */
export const serviceTypeIcon = {
    LIGHT_TRANSPORT: 'fa-truck-pickup',
    HEAVY_TRANSPORT: 'fa-truck'
}

export const ALERT_TYPES = {
    "DISCHARGE_TANK_1": { icon: "fa-arrow-circle-down", text: "alerts.type.dischargeTank1", color: "red", type: "DISCHARGE" },
    "DISCHARGE_TANK_2": { icon: "fa-arrow-circle-down", text: "alerts.type.dischargeTank2", color: "red", type: "DISCHARGE" },
    "DISCHARGE_TANK_3": { icon: "fa-arrow-circle-down", text: "alerts.type.dischargeTank3", color: "red", type: "DISCHARGE" },
    "DISCHARGE_TANK_4": { icon: "fa-arrow-circle-down", text: "alerts.type.dischargeTank4", color: "red", type: "DISCHARGE" },
    "CHARGE_TANK_1": { icon: "fa-arrow-circle-up", text: "alerts.type.chargeTank1", color: "blue", type: "CHARGE" },
    "CHARGE_TANK_2": { icon: "fa-arrow-circle-up", text: "alerts.type.chargeTank2", color: "blue", type: "CHARGE" },
    "CHARGE_TANK_3": { icon: "fa-arrow-circle-up", text: "alerts.type.chargeTank3", color: "blue", type: "CHARGE" },
    "CHARGE_TANK_4": { icon: "fa-arrow-circle-up", text: "alerts.type.chargeTank4", color: "blue", type: "CHARGE" },
    "IDLING": { icon: "fa-arrow-circle-down", text: "alerts.type.idling", color: "red", type: "IDLING" },
    "NO_ALERT": { icon: "fa-thumbs-up", text: "alerts.type.noAlert", color: "black", type: "NO_ALERT" }
}

export const ALERT_TYPE_ROUTE = {
    CHARGE: "chargeSummary",
    DISCHARGE: "dischargeSummary",
    IDLING: "idlingDischargeSummary"
}